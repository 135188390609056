<template>
  <path id="Union_11" data-name="Union 11" d="M33.023,64h0ZM5.214,64A5.221,5.221,0,0,1,0,58.784V20.2h28.84L2.834,8.225l2.762-6A3.821,3.821,0,0,1,10.671.354l9.161,4.218.728-1.581a3.828,3.828,0,0,1,5.072-1.875l7.578,3.49a3.828,3.828,0,0,1,1.874,5.072l-.728,1.582L43.5,15.473a3.824,3.824,0,0,1,1.874,5.071l-2.763,6-4.379-2.016V58.784A5.221,5.221,0,0,1,33.023,64ZM2.087,58.784a3.132,3.132,0,0,0,3.127,3.129H33.023a3.133,3.133,0,0,0,3.129-3.129V23.568l-2.781-1.281H2.087ZM41.594,23.778l1.89-4.106a1.739,1.739,0,0,0-.851-2.3L9.8,2.248A1.737,1.737,0,0,0,8.466,2.2a1.714,1.714,0,0,0-.974.9L5.6,7.2,33.973,20.269a1.035,1.035,0,0,1,.13.06l3.478,1.6q.047.019.092.042ZM32.461,10.387l.729-1.582a1.738,1.738,0,0,0-.85-2.3L24.762,3.011a1.738,1.738,0,0,0-2.308.853l-.726,1.58ZM26.428,53.224V29.588a1.044,1.044,0,0,1,2.087,0V53.224a1.044,1.044,0,0,1-2.087,0Zm-8.342,0V29.588a1.043,1.043,0,0,1,2.086,0V53.224a1.043,1.043,0,0,1-2.086,0Zm-8.341,0V29.588a1.043,1.043,0,0,1,2.086,0V53.224a1.043,1.043,0,0,1-2.086,0Z" transform="translate(0.499 0.511)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
</template>

<script>
export default {
name: "IconSupprimer"
}
</script>

<style scoped>

</style>